.list-container {
  display: flex;
  flex: 1;
  border: 1px solid red;
  flex-direction: column;
  padding: 1em;
}

.list-container h1 {
  font-size: 48px;
  margin: 0;
  padding: 0;
  padding-bottom: 0.3em;
  font-weight: 600;
}

.list-container .small {
  font-size: 0.5em;
  color: rgb(142, 142, 147);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.list-item {
  font-size: 34px;
  margin: 0;
  display: flex;
  flex: 0;
  align-items: center;
}

.list-item .controls {
  margin-right: 0.5em;
  color: rgb(199, 199, 204);
}

.list-item .content {
  padding: 0.1em;
  color: rgb(51, 51, 51);
}

.list-item .important {
  color: rgb(255, 59, 48);
}

.list-item .running {
  color: rgb(52, 199, 89);
}

.list-item .finished {
  color: #eee;
  text-decoration: line-through;
}

.list-item .new-input {
  font-size: 34px;
  border: 0;
  color: rgb(142, 142, 147);
  margin-bottom: 0.1em;
}

.list-item.important .content:hover {
  background-color: rgb(255, 59, 48);
}

.list-item .content:hover {
  cursor: pointer;
  /* background-color: rgb(0, 122, 255);
  color: #fff; */
  text-decoration: underline;
}

.list-item .controls:hover {
  cursor: pointer;
  /* color: ; */
}
