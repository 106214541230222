.task-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1em;
}

.task-container h1 {
  font-size: 48px;
  margin: 0;
  padding: 0;
  padding-bottom: 0.3em;
  font-weight: 600;
}

.single-item {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.single-item .content {
  font-size: 88px;
  text-align: center;
}

.single-item .controls {
  margin-top: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 0;
  align-self: stretch;
}

.task-container .duration {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.single-item .button {
  padding: 14px;
  padding-left: 44px;
  padding-right: 44px;
  color: #fff;
  font-size: 17px;
  border-radius: 11px;
}

.button:hover {
  cursor: pointer;
}

.button.blue {
  background-color: rgb(0, 122, 255);
}

.button.blue:hover {
  background-color: rgb(10, 132, 255);
}

.button.gray {
  background-color: rgb(174, 174, 178);
}

.button.gray {
  background-color: rgb(99, 99, 102);
}
